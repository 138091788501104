import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

// Components
import Layout from '../components/Layout';
import EnLayout from '../components/EnLayout';
import JaLayout from '../components/JaLayout';

// Language
import { getPackOf } from '../lang';
const LANG_ZH = getPackOf('zh');
const LANG_EN = getPackOf('en');
const LANG_JA = getPackOf('ja');

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        date
        source
        coverImg {
          publicURL
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }
  }
`;

const NewsPost = (props) => {
  const {
    data: {
      markdownRemark: {
        frontmatter: { title, date, coverImg, source },
        html,
      },
    },
    pageContext: { langKey },
  } = props;

  return (
    <>
      {langKey === 'zh' && (
        <Layout>
          <section id="page_bn" className="pb-1" style={{ backgroundColor: '#efefef', marginTop: '-16px' }}>
            <div className="container">
              <div id="breadcrumb">
                <div className="container radius" style={{ backgroundColor: '#7aacd2' }}>
                  <div className="">
                    <div className="col-12 pt-2">
                      <nav aria-label="breadcrumb">
                        <ol
                          className="breadcrumb small text-white pt-3 pb-2 bg-js"
                          style={{ backgroundColor: '#1169ac' }}
                        >
                          <li className="breadcrumb-item">
                            <Link to="/" style={{ color: '#fff' }}>
                              {LANG_ZH.首頁}
                            </Link>
                          </li>
                          <li className="breadcrumb-item">
                            <Link to="/news" style={{ color: '#fff' }}>
                              {LANG_ZH.新聞中心}
                            </Link>
                          </li>
                          <li className="breadcrumb-item">{title}</li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="case" className="my-3">
            <div className="container ">
              <div className="row">
                <div className="col-12 h2">{title}</div>
                <div className="col-12">
                  {LANG_ZH.發布日期}：{date}
                </div>
                <div className="col-12">
                  {LANG_ZH.新聞來源}：{source}
                </div>
                <div className="col-12">
                  {coverImg && coverImg.childImageSharp ? (
                    <Img fluid={coverImg.childImageSharp.fluid} alt="news" className="img-thumbnail" />
                  ) : (
                    <img src={coverImg.publicURL} alt="news" class="img-thumbnail" />
                  )}
                </div>
                <div id="" className="col-12">
                  <hr />
                </div>
                <div className="col-12">
                  <div dangerouslySetInnerHTML={{ __html: html }} />
                </div>
              </div>
            </div>
          </section>
        </Layout>
      )}
      {langKey === 'en' && (
        <EnLayout>
          <section id="page_bn" className="pb-1" style={{ backgroundColor: '#efefef', marginTop: '-16px' }}>
            <div className="container">
              <div id="breadcrumb">
                <div className="container radius" style={{ backgroundColor: '#7aacd2' }}>
                  <div className="">
                    <div className="col-12 pt-2">
                      <nav aria-label="breadcrumb">
                        <ol
                          className="breadcrumb small text-white pt-3 pb-2 bg-js"
                          style={{ backgroundColor: '#1169ac' }}
                        >
                          <li className="breadcrumb-item">
                            <Link to="/en" style={{ color: '#fff' }}>
                              {LANG_EN.首頁}
                            </Link>
                          </li>
                          <li className="breadcrumb-item">
                            <Link to="/en/news" style={{ color: '#fff' }}>
                              {LANG_EN.新聞中心}
                            </Link>
                          </li>
                          <li className="breadcrumb-item">{title}</li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="case" className="my-3">
            <div className="container ">
              <div className="row">
                <div className="col-12 h2">{title}</div>
                <div className="col-12">
                  {LANG_EN.發布日期} :{date}
                </div>
                <div className="col-12">
                  {LANG_EN.新聞來源} :{source}
                </div>
                <div className="col-12">
                  {coverImg && coverImg.childImageSharp ? (
                    <Img fluid={coverImg.childImageSharp.fluid} alt="news" className="img-thumbnail" />
                  ) : (
                    <img src={coverImg.publicURL} alt="news" class="img-thumbnail" />
                  )}
                </div>
                <div id="" className="col-12">
                  <hr />
                </div>
                <div className="col-12">
                  <div dangerouslySetInnerHTML={{ __html: html }} />
                </div>
              </div>
            </div>
          </section>
        </EnLayout>
      )}
      {langKey === 'ja' && (
        <JaLayout>
          <section id="page_bn" className="pb-1" style={{ backgroundColor: '#efefef', marginTop: '-16px' }}>
            <div className="container">
              <div id="breadcrumb">
                <div className="container radius" style={{ backgroundColor: '#7aacd2' }}>
                  <div className="">
                    <div className="col-12 pt-2">
                      <nav aria-label="breadcrumb">
                        <ol
                          className="breadcrumb small text-white pt-3 pb-2 bg-js"
                          style={{ backgroundColor: '#1169ac' }}
                        >
                          <li className="breadcrumb-item">
                            <Link to="/ja" style={{ color: '#fff' }}>
                              {LANG_JA.首頁}
                            </Link>
                          </li>
                          <li className="breadcrumb-item">
                            <Link to="/ja/news" style={{ color: '#fff' }}>
                              {LANG_JA.新聞中心}
                            </Link>
                          </li>
                          <li className="breadcrumb-item">{title}</li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="case" className="my-3">
            <div className="container ">
              <div className="row">
                <div className="col-12 h2">{title}</div>
                <div className="col-12">
                  {LANG_JA.發布日期}：{date}
                </div>
                <div className="col-12">
                  {LANG_JA.新聞來源}：{source}
                </div>
                <div className="col-12">
                  {coverImg && coverImg.childImageSharp ? (
                    <Img fluid={coverImg.childImageSharp.fluid} alt="news" className="img-thumbnail" />
                  ) : (
                    <img src={coverImg.publicURL} alt="news" class="img-thumbnail" />
                  )}
                </div>
                <div id="" className="col-12">
                  <hr />
                </div>
                <div className="col-12">
                  <div dangerouslySetInnerHTML={{ __html: html }} />
                </div>
              </div>
            </div>
          </section>
        </JaLayout>
      )}
    </>
  );
};

export default NewsPost;
